/*---
Custom Color add to theme
---*/
.modal {
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5); }
  .modal .change-password .input-group-wrapper {
    position: relative; }
  .modal .change-password .bottom-margin {
    margin-bottom: 80px; }
  .modal .iphone-SE-top.mt {
    margin-top: 2rem !important; }
