/*---
Custom Color add to theme
---*/
.card.prize-pool {
  margin-bottom: 20px;
  width: 260px;
  min-height: 410px;
  border-radius: 18px;
  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
  background: #fff; }
  .card.prize-pool .card-header {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #3e68ff;
    text-align: center;
    color: #fff;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1); }
  .card.prize-pool .card-img {
    height: 230px;
    border-radius: 18px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    object-fit: cover; }
  .card.prize-pool .card-body {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    background: transparent; }
    .card.prize-pool .card-body.first {
      border-top: 5px solid #ffd81d; }
    .card.prize-pool .card-body.second {
      border-top: 5px solid #aabaca; }
    .card.prize-pool .card-body.third {
      border-top: 5px solid #e02d58; }
    .card.prize-pool .card-body.fourth {
      border-top: 5px solid #1a46e5; }
  .card.prize-pool:hover {
    box-shadow: 0 6px 26px -10px rgba(60, 64, 85, 0.4); }
  .card.prize-pool .card-body figure {
    margin-top: -105px; }
    .card.prize-pool .card-body figure .oval {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      box-shadow: 0 3px 4px -3px rgba(0, 0, 0, 0.4);
      background-color: rgba(255, 255, 255, 0.7);
      position: relative; }
      .card.prize-pool .card-body figure .oval .mask {
        position: absolute;
        top: 5px;
        left: 5px;
        height: 90px;
        width: 90px;
        box-shadow: 0 3px 29px -10px #3c4055;
        background-color: #ffffff;
        border-radius: 50%; }
        .card.prize-pool .card-body figure .oval .mask img {
          padding: 10px;
          height: 90px;
          width: 90px; }
    .card.prize-pool .card-body figure figcaption {
      margin-top: 10px;
      margin-bottom: 10px; }
