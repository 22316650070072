/*---
Custom Color add to theme
---*/
.upcoming-tournament, .prize-pull, .about-mtn-treasure {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }

.todays-challange .game-screen {
  width: 100%;
  height: 390px;
  background: transparent;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 24px 40px -16px #3e68ff; }
  .todays-challange .game-screen .game-banner {
    object-fit: cover;
    height: 100%;
    width: 100%; }
  .todays-challange .game-screen .ply-btn {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    z-index: 5; }
  .todays-challange .game-screen .footer {
    background-color: rgba(26, 70, 229, 0.9);
    position: absolute;
    bottom: 0px;
    z-index: 5; }
    .todays-challange .game-screen .footer .media {
      padding: 15px 0px; }
      .todays-challange .game-screen .footer .media .media-body .timer {
        max-width: 32px;
        max-height: 32px; }
      .todays-challange .game-screen .footer .media .media-body span {
        font-weight: 500; }

.todays-challange .Hunters-Challenge-C {
  margin-top: 3rem;
  font-weight: 500;
  line-height: 1.5; }

@media (max-width: 991.98px) {
  .todays-challange .challange-content br,
  .todays-challange .Hunters-Challenge-C br {
    display: none; } }

.prize-pull {
  margin-top: -300px; }
  .prize-pull .heading {
    margin-top: 370px; }
  @media (max-width: 991.98px) {
    .prize-pull {
      margin-top: 3rem; }
      .prize-pull .heading {
        margin-top: 3rem; } }

.leader-board .padder.leaderboard {
  padding: 6rem 0rem; }

.leader-board .score-info .score-info-wrapper {
  background: #fff;
  box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.2);
  border-radius: 16px; }
  .leader-board .score-info .score-info-wrapper p {
    margin-bottom: 3px; }
  @media (max-width: 991.98px) {
    .leader-board .score-info .score-info-wrapper h4 {
      font-size: 15px; } }
  .leader-board .score-info .score-info-wrapper .profile-pic img {
    max-width: 96px;
    box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
  .leader-board .score-info .score-info-wrapper .col {
    /*background-image: url("../images/silver@3x.png");*/
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    width: 100%;
    height: 100%; }
    .leader-board .score-info .score-info-wrapper .col.br-1::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 1px;
      bottom: 0px;
      background-color: #e0e4e8; }

.leader-board .scoreboard-header .scoreboard-header-wrapper {
  background-color: #3e68ff;
  border-radius: 40px; }
  .leader-board .scoreboard-header .scoreboard-header-wrapper ul {
    margin-bottom: 0px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .profile-pic {
      width: 65px;
      box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.3);
      border-radius: 50%; }
      @media (max-width: 991.98px) {
        .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .profile-pic {
          width: 40px; } }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-name {
      width: 300px; }
      .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-name p {
        width: 100%; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-score {
      width: 100px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li li:first-child {
      width: 50px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li li p {
      margin-bottom: 0px; }

.leader-board .scoreboard .scoreboard-wrapper p {
  margin-bottom: 0px; }

.leader-board .scoreboard .scoreboard-wrapper .rank {
  width: 50px; }

.leader-board .scoreboard .scoreboard-wrapper .profile-pic {
  width: 65px;
  box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.3);
  border-radius: 50%; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
  width: 300px; }
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name p {
    width: 100%; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-score {
  width: 100px; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row li:first-child {
  width: 50px; }

.leader-board .scoreboard .scoreboard-wrapper .bb-1 {
  box-shadow: inset 0 -1px 0 0 #e0e4e8; }

.leader-board .scoreboard .scoreboard-wrapper.score-result {
  background-color: #3e68ff;
  border-radius: 36px; }
  .leader-board .scoreboard .scoreboard-wrapper.score-result ul {
    margin-bottom: 0px; }

@media screen and (max-width: 340px) {
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
    width: 130px !important; } }

@media (max-width: 991.98px) {
  .leader-board .score-info {
    padding: 0px 15px; }
    .leader-board .score-info .score-info-wrapper {
      border-radius: 8px; }
      .leader-board .score-info .score-info-wrapper .br-1::after {
        display: none; }
  .leader-board .scoreboard-header {
    padding: 0px; }
  .leader-board .padder.leaderboard {
    padding: 3rem 0rem; }
  .leader-board .scoreboard .scoreboard-wrapper .rank {
    width: 36px; }
  .leader-board .scoreboard .scoreboard-wrapper .profile-pic {
    width: 40px; }
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
    width: 180px; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name p {
      width: 100%; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name .player-name {
      font-size: 12px; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name .player-score {
      font-size: 16px; } }

@media (max-width: 767.98px) {
  .leader-board .scoreboard-header {
    padding: 0px 15px; } }

.about-mtn-treasure .tournament-accordian .card {
  background: transparent; }
  .about-mtn-treasure .tournament-accordian .card.bb-1 {
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .about-mtn-treasure .tournament-accordian .card .card-header {
    background: transparent;
    padding: 25px 0px;
    cursor: pointer; }
    .about-mtn-treasure .tournament-accordian .card .card-header .arrow {
      transition: all 0.5s ease-in-out; }
  .about-mtn-treasure .tournament-accordian .card .card-body {
    padding: 0px 0px 25px 0px; }
    .about-mtn-treasure .tournament-accordian .card .card-body img {
      max-width: 65px; }

@media (max-width: 991.98px) {
  .about-mtn-treasure p br {
    display: none; } }

.fold .fold_trigger:before {
  padding-right: 1rem;
  transition: transform 400ms;
  transform-origin: 20%; }

.fold .fold_trigger.open {
  transform: rotateZ(-180deg); }

.fold .fold_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease; }
  .fold .fold_content.open {
    max-height: 400px; }

.text-secondary-color {
  color: #1a46e5 !important; }

.errorSuccessMsgForJoin {
  margin-left: 10px; }

.accordion {
  border: none !important; }
  .accordion :focus {
    outline: none; }
