//Navigation Logo width Customization
//Extended Css

%comman-title-class {
  font-family: $headings-font-family;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@mixin h1-title() {
  @extend %comman-title-class;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

@mixin h2-title($weight) {
  @extend %comman-title-class;
  font-size: 32px;
  font-weight: $weight;
  line-height: 1.25;
}

@mixin h4-title($weight) {
  @extend %comman-title-class;
  font-size: 21px;
  font-weight: $weight;
  line-height: 1.33;
}

@mixin h5-title($weight) {
  @extend %comman-title-class;
  font-size: 18px;
  font-weight: $weight;
  line-height: 1.56;
}

@mixin h6-title($weight) {
  @extend %comman-title-class;
  font-size: 16px;
  font-weight: $weight;
  line-height: 1.38;
}