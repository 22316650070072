$pell-actionbar-color: #FFF !default;
$pell-border-color: rgba(10, 10, 10, 0.1) !default;
$pell-border-style: solid !default;
$pell-border-width: 1px !default;
$pell-button-height: 30px !default;
$pell-button-selected-color: #F0F0F0 !default;
$pell-button-width: 30px !default;
$pell-content-height: 300px !default;
$pell-content-padding: 10px !default;

.pell {
  border: $pell-border-width $pell-border-style $pell-border-color;
  box-sizing: border-box;
}

.pell-content {
  box-sizing: border-box;
  height: $pell-content-height;
  outline: 0;
  overflow-y: auto;
  padding: $pell-content-padding;
}

.pell-actionbar {
  background-color: $pell-actionbar-color;
  border-bottom: $pell-border-width $pell-border-style $pell-border-color;
}

.pell-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: $pell-button-height;
  outline: 0;
  width: $pell-button-width;
  vertical-align: bottom;
}

.pell-button-selected {
  background-color: $pell-button-selected-color;
}
