/*---
Custom Color add to theme
---*/
.modalV1.modal.modal-unfollow .modal-footer {
  padding: 0 !important; }
  @media (max-width: 991.98px) {
    .modalV1.modal.modal-unfollow .modal-footer {
      height: auto !important; } }

@media (min-width: 768px) {
  .modalV1.modal.modal-unfollow .modal-dialog {
    max-width: 332px; } }
