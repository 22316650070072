/*---
Custom Color add to theme
---*/
.select .react-select span {
  border: none !important; }

.select .react-select__control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #e0e4e8;
  border-radius: 0px; }
  .select .react-select__control:hover {
    border-bottom: 1px solid #e0e4e8; }
  .select .react-select__control--is-focused {
    border: none !important;
    box-shadow: none; }
    .select .react-select__control--is-focused .focus-border-test {
      width: 100%;
      transition: 0.4s; }

.focus-border-test {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3e68ff;
  transition: 0.4s; }

.effect-17 {
  border-bottom: 1px solid red !important;
  width: 100%;
  transition: 0.4s; }

.hostPageForm textarea {
  height: 100px !important; }

.remove-avatar {
  position: absolute;
  bottom: 155px;
  right: 987px;
  background: #fff;
  box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 5px;
  z-index: 19; }
