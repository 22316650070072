.banner {
  &.backend {
    background-image: url("../assets/images/backend-banner.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

#rootwizard {
  .backend-pill {
    &.nav-pills {
      box-shadow: none;
      .nav-item {
        pointer-events: none;
        z-index: 2;
        p {
          color: rgba(#fff, 60%);
          font-weight: 500;
        }

        position: relative;
        &:not(:last-child) {
          &:after {
            content: "";
            z-index: -1;
            position: absolute;
            right: -20px;
            top: -20px;
            bottom: 0;
            background-image: url("../assets/images/dot-arrow.png");
            width: 100%;
            background-repeat: no-repeat;
            background-position: center right;
            opacity: 0.5;
            transition: 1s;
            left: 0;
          }
        }

        &.transparentDiv {
          pointer-events: visible;
          z-index: 2;
          p {
            color: rgba(#fff, 100%);
          }
          &:after {
            content: "";
            opacity: 1;
            left: 20px;
            z-index: -1;
          }
          .active {
            transform: scale(1.2);
            transition: all 0.5s ease-in-out;
          }
        }

        .nav-link {
          background-color: transparent;
          .circle {
            border: 2px solid rgba(#fff, 60%);
            border-radius: 50%;
            display: inline-block;
            .inner {
              background-color: rgba(#fff, 60%);
              border-radius: 50%;
              margin: 5px;
              img {
                &.on {
                  display: none;
                }
                &.off {
                  display: flex;
                }
                padding: 15px;
              }
            }
          }
          &.active,
          &.active-fixed {
            .circle {
              border: 2px solid #fff;
              .inner {
                background-color: #fff;
                img {
                  &.on {
                    display: flex;
                  }
                  &.off {
                    display: none;
                  }
                }
              }
            }
          }
        }

        color: #fff;
        p {
          margin-bottom: 0px;
        }
      }
    }
  }

  .tab-content-wrapper {
    background-image: linear-gradient(to top, #ffffff, #f8faff);
    border-top: 1px solid rgba(224, 228, 232, 0.5);
    margin-bottom: 100px;
    .tab-1,
    .tab-2,
    .tab-3 {
      .media {
        img {
          width: 120px;
        }
      }
      .section-info {
        padding-left: 10%;
        @include media-breakpoint-down(md) {
          padding-left: 15px;
        }
      }
    }

    .tab-1 {
      .with-lable {
        .create-tournament-wrapper {
          .form-control {
            font-size: 36px;
            font-weight: bold;
            padding-bottom: 20px;
            height: 50px;
            @include media-breakpoint-down(md) {
              font-size: 18px;
            }
          }
          .effect-16 ~ label {
            top: -20px;
            font-size: 36px;
            font-weight: bold;
            color: rgba(#a3b8cc, 50%);

            @include media-breakpoint-down(md) {
              font-size: 18px;
              top: 5px;
            }
          }
          .effect-16:focus ~ label,
          .has-content.effect-16 ~ label {
            top: -36px;
            font-size: 16px;
            font-weight: 500;
            color: $default;
          }
        }
      }
    }

    .tab-3 {
      .bg-rewards {
        background: #fff;
        padding: 30px;
        box-shadow: 0 10px 10px 5px rgba(167, 184, 245, 0.05);
        border: 1px solid rgba($light-grey-default, 25%);
        overflow: hidden;
      }
      .form-info {
        ul {
          li {
            &.list-group-control {
              &:first-child {
                padding-left: 0px;
              }
            }
            .select-game-box {
              box-shadow: none;
            }
          }
        }
      }
    }

    .upload-image {
      cursor: pointer;
      .file {
        visibility: hidden;
        position: absolute;
      }
      .image-container {
        border: 1px dashed #aabaca;
      }
      .upload-button {
        .reward-img {
          width: 50px;
          height: 50px;
        }
      }
      .add-new-rewards-danger {
        border: 0;
        border: 1px dashed red;
      }
      .file-size-danger {
        color: red;
      }
    }
  }

  .add-new-rewards {
    min-height: 310px;
    border: 1px dashed $light-grey-default;

    img {
      width: 50px;
    }
  }

  .tab-indication-wrapper {
    background-image: linear-gradient(to top, #f8fbfe, #fff);
    border-top: 1px solid rgba($light-grey-default, 30%);
    position: fixed;
    bottom: 0px;
    left: 0px;
    box-shadow: 0 10px 8px 15px rgba(86, 119, 151, 0.08);
    padding: 7px;
    z-index: 25;
    margin-bottom: 27px;
    .mw-100 {
      min-width: 100px;
    }
  }
  .table {
    tbody {
      tr {
        td,
        th {
          white-space: nowrap;
        }
      }
    }
  }
}

.zIndex-1 {
  z-index: 3;
}
.zIndex-0 {
  z-index: 0;
}

.zIndex-4 {
  z-index: 4;
}

.zIndex-11 {
  z-index: 11;
}

.zIndex-12 {
  z-index: 12;
}

.zIndex-13 {
  z-index: 13;
}

.zIndex-14 {
  z-index: 14;
}

.zIndex-15 {
  z-index: 15;
}

@include media-breakpoint-down(lg) {
  .dropdown__list {
    &.customized {
      position: relative;
      top: -55px;
    }
  }
  .nav-pills {
    li {
      display: none;
      &.mobile-active {
        display: block;
      }
      &::after {
        display: none;
      }
    }
  }
}

.form-info {
  ul {
    li {
      &.list-group-control {
        padding-left: 5px;
        padding-right: 5px;
        @include media-breakpoint-down(sm) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .select-game-box {
        background: #fff;
        padding: 15px 10px;
        box-shadow: 0 10px 10px 5px rgba(167, 184, 245, 0.05);
        border: 1px solid rgba($light-grey-default, 25%);

        label {
          &.custom-control {
            cursor: pointer;
            padding-left: 0px;
            &.custom-radio {
              --color: #1a46e5;

              .custom-control-input {
                display: none;
                &:checked ~ .custom-control-indicator {
                  background-color: var(--color);
                  border-color: var(--color);
                  background-size: 100%;
                }
              }

              .custom-control-indicator {
                background-image: url("../assets/images/checked-circle.png");
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 4px;
                right: 0;
                width: 20px;
                height: 20px;
                border: 2px solid $light-violet;
                transition: 0.3s;
                background: transperent;
                background-size: 0%;
                padding: 3px;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
          .labelc-content {
            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

.challange-heading-main {
  .effect-16 ~ label {
    top: -20px;
    font-size: 36px;
    font-weight: bold;
    color: rgba(163, 184, 204, 0.5);
  }

  .form-control {
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    height: 50px;
  }
}

.image-area-1 {
  border: 1px dashed #aabaca;
  min-height: 200px;

  img {
    max-height: 200px;
    max-width: 1920px;
  }
}
.image-area-2 {
  min-height: 200px;
  max-width: 750px;
  img {
    max-height: 256px;
    max-width: 750px;
  }

  border: 1px dashed #aabaca;
}

.leader-board-web {
  border: 1px dashed #aabaca;
  min-height: 90px;

  img {
    max-height: 90px;
    max-width: 728px;
  }
}

.bg-host-backend {
  background-image: linear-gradient(to top, #f8faff, #ffffff);
  border: 1px dashed rgba(170, 186, 202, 0.7);
}

.brand-avatar {
  .avatar-wrapper {
    position: relative;
    width: 200px;
    height: 200px;

    .upload-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.85);
      padding-top: 5px;
      padding-bottom: 35px;
      width: 200px;
      margin: 0 auto;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      text-align: center;
    }
  }
}
.card {
  &.admin-raffle {
    .card-body {
      .card-img {
        border: 1px dashed rgba(170, 186, 202, 0.7);
        border-radius: 24px;
      }

      &::before {
        border-bottom: 1px dashed rgba(170, 186, 202, 0.7);
        border-left: 1px dashed rgba(170, 186, 202, 0.7);
        border-right: 1px dashed rgba(170, 186, 202, 0.7);
      }
      &::after {
        border-top: 1px dashed rgba(170, 186, 202, 0.7);
        border-left: 1px dashed rgba(170, 186, 202, 0.7);
        border-right: 1px dashed rgba(170, 186, 202, 0.7);
        bottom: 0px;
      }

      .btn-raffle-admin {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 50px;
        margin: 0px 20px;
        padding: 10px 0px;
        line-height: 15px;
      }
    }
  }
}

.live-tournament {
  &.admin-panel-raffle {
    background-image: linear-gradient(to top, #ffffff, #fff);
  }
}

.add-adv-admin {
  width: 100px;
  line-height: 20px;
  border-radius: 100px;
  border-width: 2px;
}

.admin-table {
  min-height: 350px;
  table {
    thead {
      background-color: $secondary;
      tr {
        th {
          color: #fff;
        }
      }
    }
  }
}

.zIndex-2 {
  z-index: 10;
}
.zIndex-3 {
  z-index: 20;
}

.custom-control {
  &.fill-checkbox {
    --color: #1a46e5;

    .fill-control-input {
      display: none;
      &:checked ~ .fill-control-indicator {
        background-color: var(--color);
        border-color: var(--color);
        background-size: 80%;
      }
    }
    .fill-control-indicator {
      background-image: url("../assets/images/checked.png");
      border-radius: 3px;
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid $light-violet;
      transition: 0.3s;
      background: transperent;
      background-size: 0%;
      padding: 3px;
      background-position: center;
      background-repeat: no-repeat;

      &.backend {
        left: auto;
        right: 0;
      }
    }
    .fill-control-description {
      font-size: 12px;
      display: flex;
      align-items: center;
      line-height: 28px;
      padding-left: 10px;

      a {
        color: $secondary;
      }
    }
  }
}
.popup-dismiss {
  position: absolute;
  // top: 25px;
  // right: 25px;
  cursor: pointer;
  // z-index: 101;
}
.bg-rewards {
  .popup-dismiss {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 101;
  }
}

.active-page {
  color: #fff !important;
}
.disable-div {
  pointer-events: none !important;
  opacity: 0.7 !important;
}
