.nav-pills {
  .nav-item {
    &.col {
      padding-left: 0px;
      padding-right: 0px;
    }
    .nav-link {
      border-radius: 31px;
      font-size: 12px;
      font-weight: 500;
      color: $lightish-blue;
      &.active {
        background-color: $lightish-blue;
        color: #fff;
      }
    }
  }
  box-shadow: inset 0 0 0 2px $lightish-blue;
  border-radius: 31px;
}
