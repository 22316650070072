/*---
Custom Color add to theme
---*/
@media (max-width: 767.98px) {
  .modalV1.modal .modal-dialog-centered {
    height: 100vh;
    margin: 0px; }
    .modalV1.modal .modal-dialog-centered .modal-content {
      border: 0px;
      border-radius: 0px; } }

.modalV1.modal .modal-content {
  border-radius: 24px;
  overflow: hidden; }
  .modalV1.modal .modal-content .modal-body {
    padding: 3.5rem; }

@media (max-width: 991.98px) {
  .modalV1.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1029; }
    .modalV1.modal.without-footer .modal-dialog .modal-content .modal-body {
      bottom: 0px; }
    .modalV1.modal .modal-dialog {
      position: fixed;
      margin: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      max-width: 100%; }
      .modalV1.modal .modal-dialog .modal-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
        border: none; }
        .modalV1.modal .modal-dialog .modal-content .modal-header {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 52px;
          border: 0; }
        .modalV1.modal .modal-dialog .modal-content .modal-body {
          position: fixed;
          top: 72px;
          bottom: 52px;
          width: 100%;
          font-weight: 300;
          overflow: hidden;
          -webkit-overflow-scrolling: touch;
          padding-top: 100px;
          padding-left: 50px;
          padding-right: 50px; }
          .modalV1.modal .modal-dialog .modal-content .modal-body.landscape {
            position: absolute;
            overflow-y: auto; } }
        @media screen and (max-width: 991.98px) and (max-width: 340px) {
          .modalV1.modal .modal-dialog .modal-content .modal-body {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 80px; } }

@media (max-width: 991.98px) {
        .modalV1.modal .modal-dialog .modal-content .modal-footer {
          position: absolute;
          right: 0;
          bottom: 0px;
          left: 0;
          height: 52px;
          width: 100%; } }

.modalV1.modal .card-popup {
  position: relative;
  z-index: 100; }
  .modalV1.modal .card-popup .mt-negative-4 {
    margin-top: -5px;
    background: #fff;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 500; }
  .modalV1.modal .card-popup .gutter-space {
    padding: 0px 15px; }
    .modalV1.modal .card-popup .gutter-space [class^="col"] {
      padding-right: 0px;
      padding-left: 0px; }
  .modalV1.modal .card-popup .pt-custom-1 {
    padding-top: 1.8rem; }
  .modalV1.modal .card-popup .creat-account {
    font-size: 16px; }

.modalV1.modal .popup-dismiss {
  position: absolute;
  right: 25px;
  cursor: pointer;
  top: 25px; }
  @media (max-width: 991.98px) {
    .modalV1.modal .popup-dismiss {
      top: 80px; } }

.modalV1 .cross-background {
  position: absolute;
  background: radial-gradient(circle at 111% 58%, #3e68ff, #1a46e5 50%, #0024a8); }
  .modalV1 .cross-background:after {
    content: "";
    background-image: url("../../assets/images/login-shape-secondary.png");
    position: absolute;
    background-repeat: no-repeat; }
  .modalV1 .cross-background.web {
    bottom: -5px;
    right: 0px;
    clip-path: polygon(100% 55%, 100% 100%, -50% 100%);
    -webkit-clip-path: polygon(100% 55%, 100% 100%, -50% 100%);
    width: 100%;
    height: 100%; }
    .modalV1 .cross-background.web:after {
      background-size: 100%;
      background-position: top left;
      top: 55%;
      right: 10%;
      width: 100%;
      height: 100%; }
  .modalV1 .cross-background.mobile {
    margin-top: -190px;
    left: 0;
    -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
    width: 100%;
    height: 100%; }
    .modalV1 .cross-background.mobile:after {
      background-position: top right;
      background-size: 100% 100%;
      top: 0;
      left: -20px;
      width: 80%;
      height: 100%; }

.modalV1 .popup-responsive {
  padding-top: 72px; }
  .modalV1 .popup-responsive.position-fixed .cross-background {
    background: #1a46e5;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -190px;
    left: 0;
    -webkit-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%); }
  .modalV1 .popup-responsive.position-fixed .iphone-SE-creat-account.mt-4 {
    margin-top: 0.5rem !important; }
  .modalV1 .popup-responsive.position-fixed .privacy-policy .mt-2 {
    margin-top: 0px !important; }

.modalV1 .iphone-SE-top.mt-5 {
  margin-top: 1.8rem !important; }

@media (max-width: 991.98px) {
  .modal-backdrop {
    z-index: 1028 !important; } }
