/*---
Custom Color add to theme
---*/
.leader-board .padder.leaderboard {
  padding: 6rem 0rem; }

@media (max-width: 1199.98px) {
  .leader-board .mobile-panel {
    min-height: 80px; }
    .leader-board .mobile-panel .small {
      font-size: 14px; }
    .leader-board .mobile-panel .profile-pic-96 {
      width: 70px;
      height: 70px; } }

.leader-board .score-info .score-info-wrapper {
  background: #fff;
  box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.2);
  border-radius: 16px; }
  .leader-board .score-info .score-info-wrapper p {
    margin-bottom: 3px; }
  @media (max-width: 991.98px) {
    .leader-board .score-info .score-info-wrapper h4 {
      font-size: 18px; } }
  .leader-board .score-info .score-info-wrapper .profile-pic img {
    max-width: 96px;
    box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
  .leader-board .score-info .score-info-wrapper .col {
    background-image: url("../../../assets/images/silver@3x.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    width: 100%;
    height: 100%; }
    .leader-board .score-info .score-info-wrapper .col.br-1::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 1px;
      bottom: 0px;
      background-color: #e0e4e8; }

.leader-board .scoreboard-header .scoreboard-header-wrapper {
  background-color: #3e68ff;
  border-radius: 40px; }
  .leader-board .scoreboard-header .scoreboard-header-wrapper ul {
    margin-bottom: 0px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .profile-pic {
      width: 65px;
      box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.3);
      border-radius: 50%; }
      @media (max-width: 991.98px) {
        .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .profile-pic {
          width: 40px; } }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-name {
      width: 300px; }
      .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-name p {
        width: 100%; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li .fixed-score {
      width: 100px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li li:first-child {
      width: 50px; }
    .leader-board .scoreboard-header .scoreboard-header-wrapper ul li li p {
      margin-bottom: 0px; }

.leader-board .scoreboard .scoreboard-wrapper p {
  margin-bottom: 0px; }

.leader-board .scoreboard .scoreboard-wrapper .rank {
  width: 50px; }

.leader-board .scoreboard .scoreboard-wrapper .profile-pic {
  width: 65px;
  box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.3);
  border-radius: 50%; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
  width: 300px; }
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name p {
    width: 100%; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-score {
  width: 100px; }

.leader-board .scoreboard .scoreboard-wrapper .scoreboard-row li:first-child {
  width: 50px; }

.leader-board .scoreboard .scoreboard-wrapper .bb-1 {
  box-shadow: inset 0 -1px 0 0 #e0e4e8; }

.leader-board .scoreboard .scoreboard-wrapper.score-result {
  background-color: #3e68ff;
  border-radius: 36px; }
  .leader-board .scoreboard .scoreboard-wrapper.score-result ul {
    margin-bottom: 0px; }

@media screen and (max-width: 340px) {
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
    width: 130px !important; } }

@media (max-width: 991.98px) {
  .leader-board .score-info {
    padding: 0px 15px; }
    .leader-board .score-info .score-info-wrapper {
      border-radius: 8px; }
      .leader-board .score-info .score-info-wrapper .br-1::after {
        display: none; }
  .leader-board .scoreboard-header {
    padding: 0px; }
  .leader-board .padder.leaderboard {
    padding: 3rem 0rem; }
  .leader-board .scoreboard .scoreboard-wrapper .rank {
    width: 36px; }
  .leader-board .scoreboard .scoreboard-wrapper .profile-pic {
    width: 40px; }
  .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name {
    width: 180px; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name p {
      width: 100%; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name .player-name {
      font-size: 12px; }
    .leader-board .scoreboard .scoreboard-wrapper .scoreboard-row .fixed-name .player-score {
      font-size: 16px; } }

@media (max-width: 767.98px) {
  .leader-board .scoreboard-header {
    padding: 0px 15px; } }

.more-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer; }
