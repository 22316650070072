/*---
Custom Color add to theme
---*/
.select2 .dropdown {
  position: relative;
  width: 100%; }
  .select2 .dropdown .selection-heading {
    padding: 15px 15px 15px 15px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .select2 .dropdown .down-arrow-dropdown {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 3;
    transition: all 0.5s ease-in-out; }
    .select2 .dropdown .down-arrow-dropdown.openArrow {
      transform: rotate(180deg); }
  .select2 .dropdown--open .dropdown__list {
    display: block; }

.select2 .dropdown__list {
  position: absolute;
  z-index: 2;
  display: none;
  top: -15px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
  .select2 .dropdown__list ul {
    max-height: 200px;
    overflow: auto;
    margin-bottom: 0px; }
  .select2 .dropdown__list li {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer; }
    .select2 .dropdown__list li:hover {
      background-color: rgba(224, 228, 232, 0.3); }

.select2 input {
  cursor: pointer;
  caret-color: transparent !important; }
  .select2 input:focus {
    outline: none;
    caret-color: transparent !important; }
