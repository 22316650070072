header {
  .navbar-light {
    height: 72px;
    .navbar-brand {
      display: inline-block;
      padding-top: $navbar-brand-padding-y;
      padding-bottom: $navbar-brand-padding-y;
      margin-right: 0px;
      line-height: inherit;
      white-space: nowrap;
      max-width: 18rem;

      @include media-breakpoint-down(lg) {
        max-width: 140px; // Minimum size of logo mobile
        flex: 1;
        .mainLogo {
          width: 10rem;
        }
      }

      @include hover-focus {
        text-decoration: none;
      }
    }
    .icon-menu {
      width: auto;
    }
    .nav-link {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      padding-right: $navbar-nav-link-padding-x;
      padding-left: $navbar-nav-link-padding-x;
      position: relative;
      &.active {
        &::after {
          content: "";
          position: absolute;
          height: 3px;
          width: 100%;
          left: 0;
          bottom: 0px;
          background-color: $danger;
        }
      }
    }
    box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.54);
    &.navbar {
      &.isAnyModalOpen {
        @include media-breakpoint-down(md) {
          z-index: 1030;
        }
      }
      z-index: 1025;
      @include media-breakpoint-only(lg) {
        .navbar-nav {
          .nav-link {
            padding-left: 0.8rem;
            padding-right: 0.8rem;
          }
        }
      }
      @include media-breakpoint-down(md) {
        padding: 0px 1rem;
      }
    }
    &.nav-main {
      .nav-item {
        .nav-link {
          height: 72px;
          line-height: 60px;
          .search-icon {
            max-width: 20px;
            max-height: 20px;
          }
          .chat-icon {
            max-width: 24px;
          }
          figure {
            position: relative;
            .online {
              position: absolute;
              top: 5px;
              right: 5px;
              width: 11px;
              height: 11px;
              background-image: radial-gradient(
                circle at 50% 31%,
                #73ffb3,
                #50de80
              );
              box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
              border-radius: 50%;
              border: 1px solid white;
            }
          }
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
        &:last-child {
          .nav-link {
            padding-right: 0px;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
.left-arrow-icon {
  transform: rotate(180deg);
}
.message-count {
  padding: 2px 7px;
  border-radius: 50px;
  left: -8px;
  top: -8px;
  font-size: 10px;
}