footer {
  .copyright {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    p {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
