//Typography
h1 {
    @include h1-title(); // font-size 40px

    &.h1-mb {
        margin-bottom: 26px;
    }
    margin-bottom: 0px;
}

h2 {
    @include h2-title(normal); // font-size 32px
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
    margin-bottom: 0px;
}

h4 {
    @include h4-title(normal); // font-size 21px
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
    &.bold {
        @include h4-title(bold); //16px
        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }
    &.semi-bold {
        @include h4-title(500); //16px
        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }
    margin-bottom: 0px;
}

h5 {
    @include h5-title(normal); // font-size 18px
    margin-bottom: 0px;
    &.bold {
        @include h6-title(bold); //16px

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

h6,.h6 {
    @include h6-title(normal);

    &.bold {
        @include h6-title(bold); //16px

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    margin-bottom: 0px;
}
.small {
    font-size: 12px;
    &.semi-bold {
        font-weight: 500;
    }
    &.bold {
        font-weight: bold;
    }
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

.display-1 {
    @include h1-title();
    font-size: 64px;
    line-height: 1.13;

    @include media-breakpoint-down(md) {
        font-size: 56px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 32px;
    }
}

.display-2 {
    font-size: 54px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.30;
    letter-spacing: normal;

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}
.display-3 {
    font-size: 48px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.30;
    letter-spacing: normal;

    &.bold {
        font-weight: bold;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}
