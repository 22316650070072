/*---
Custom Color add to theme
---*/
.upcoming-tournament, .prize-pull {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }

.text-direction {
  font-weight: 500; }

.featured-banner label.custom-control {
  cursor: pointer;
  padding-left: 0px; }
  .featured-banner label.custom-control.custom-radio {
    --color: #1a46e5; }
    .featured-banner label.custom-control.custom-radio .custom-control-input {
      display: none; }
      .featured-banner label.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
        background-color: var(--color);
        border-color: var(--color);
        background-size: 100%; }
    .featured-banner label.custom-control.custom-radio .custom-control-indicator {
      background-image: url("../../assets/images/checked-circle.png");
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 4px;
      right: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #a3b8cc;
      transition: 0.3s;
      background: transperent;
      background-size: 0%;
      padding: 3px;
      background-position: center;
      background-repeat: no-repeat; }

.featured-banner label .labelc-content img {
  width: 30px; }

.banner.my-tournament {
  background-image: url("../../assets/images/adv/banner-1.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover; }
  @media (max-width: 991.98px) {
    .banner.my-tournament {
      padding: 16vw;
      height: 100%;
      background-size: 100% 100%; } }

.tournamet-info-wrapper {
  padding: 25px 0px;
  background: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24); }
  .tournamet-info-wrapper p {
    margin-bottom: 0px; }
  .tournamet-info-wrapper .responsive-adjust .media .timer {
    max-width: 32px;
    max-height: 32px; }
  .tournamet-info-wrapper .responsive-adjust .media .media-body span {
    font-weight: 500; }
  .tournamet-info-wrapper .responsive-adjust .ms-flex {
    flex: 1;
    -ms-flex: 1; }
  .tournamet-info-wrapper .counter-btn-wraper span {
    font-weight: normal;
    font-size: 12px; }
  .tournamet-info-wrapper .mt-negative-50 {
    margin-top: -50px; }
  .tournamet-info-wrapper .profile-avatar {
    max-width: 140px;
    width: 100%;
    box-shadow: 0 9px 20px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
    @media (max-width: 991.98px) {
      .tournamet-info-wrapper .profile-avatar {
        max-width: 65px; } }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper .media {
      margin-top: 5px; }
    .tournamet-info-wrapper .countDown-timer {
      font-size: 11px; } }
  @media (max-width: 767.98px) {
    .tournamet-info-wrapper {
      padding: 12px 0px; }
      .tournamet-info-wrapper .title-small-responsive {
        font-size: 18px; }
      .tournamet-info-wrapper .countDown-timer {
        font-size: 11px; }
      .tournamet-info-wrapper .responsive-adjust [class^="col"] {
        padding-left: 0px; }
      .tournamet-info-wrapper .responsive-adjust a.btn.ml-2 {
        margin-left: 0px !important; }
      .tournamet-info-wrapper .responsive-adjust h6 {
        font-size: 16px; }
      .tournamet-info-wrapper .responsive-adjust .media {
        margin-top: 10px; }
        .tournamet-info-wrapper .responsive-adjust .media .timer {
          max-width: 24px;
          max-height: 24px; }
        .tournamet-info-wrapper .responsive-adjust .media .media-body h6 {
          font-size: 12px; } }
  .tournamet-info-wrapper.host-detail .social-media img {
    width: 7vw;
    max-width: 28px;
    height: 7vw;
    max-height: 28px; }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper.host-detail {
      padding: 20px 0px; }
      .tournamet-info-wrapper.host-detail .host-heading {
        margin-top: -15px;
        margin-bottom: 20px; }
        .tournamet-info-wrapper.host-detail .host-heading h1 {
          font-size: 21px; } }

.react-cntdwn-day {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-day span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-hour {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-hour span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-minute {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-minute span {
    margin-left: 3px;
    font-weight: bold !important; }

.react-cntdwn-second {
  margin-left: 5px;
  font-weight: bold !important; }
  .react-cntdwn-second span {
    margin-left: 3px;
    font-weight: bold !important; }

.todays-challange .game-screen {
  width: 100%;
  height: 390px;
  background: transparent;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 24px 40px -16px #3e68ff; }
  .todays-challange .game-screen .game-banner {
    object-fit: cover;
    height: 100%;
    width: 100%; }
  .todays-challange .game-screen .ply-btn {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    z-index: 5; }
  .todays-challange .game-screen .footer {
    background-color: rgba(26, 70, 229, 0.9);
    position: absolute;
    bottom: 0px;
    z-index: 5; }
    .todays-challange .game-screen .footer .media {
      padding: 15px 0px; }
      .todays-challange .game-screen .footer .media .media-body .timer {
        max-width: 32px;
        max-height: 32px; }
      .todays-challange .game-screen .footer .media .media-body span {
        font-weight: 500; }

.todays-challange .Hunters-Challenge-C {
  margin-top: 3rem;
  font-weight: 500;
  line-height: 1.5; }

@media (max-width: 991.98px) {
  .todays-challange .challange-content br,
  .todays-challange .Hunters-Challenge-C br {
    display: none; } }

.prize-pull {
  margin-top: -300px; }
  .prize-pull .heading {
    margin-top: 370px; }
  @media (max-width: 991.98px) {
    .prize-pull {
      margin-top: 3rem; }
      .prize-pull .heading {
        margin-top: 3rem; } }
  .prize-pull .mt-6 {
    margin-top: 5rem; }

.about-mtn-treasure .tournament-accordian .card {
  background: transparent; }
  .about-mtn-treasure .tournament-accordian .card.bb-1 {
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .about-mtn-treasure .tournament-accordian .card .card-header {
    background: transparent;
    padding: 25px 0px;
    cursor: pointer; }
    .about-mtn-treasure .tournament-accordian .card .card-header .arrow {
      transition: all 0.5s ease-in-out; }
  .about-mtn-treasure .tournament-accordian .card .card-body {
    padding: 0px 0px 25px 0px; }
    .about-mtn-treasure .tournament-accordian .card .card-body img {
      max-width: 65px; }

@media (max-width: 991.98px) {
  .about-mtn-treasure p br {
    display: none; } }

.accordion {
  border: none !important; }
  .accordion :focus {
    outline: none; }
