%avatar {
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  box-shadow: 0 3px 4px 1px rgba(60, 64, 85, 0.15);
}
.profile-pic-200 {
  width: 200px;
  height: 200px;
  @extend %avatar;
}
.profile-pic-160 {
  width: 160px;
  height: 160px;
  @extend %avatar;
}
.profile-pic-140 {
  width: 160px;
  height: 160px;
  @extend %avatar;
}

.profile-pic-120 {
  width: 120px;
  height: 120px;
  @extend %avatar;
}
.profile-pic-96 {
  width: 96px;
  height: 96px;
  @extend %avatar;
}
.profile-pic-80 {
  width: 80px;
  height: 80px;
  @extend %avatar;
}
.profile-pic-72 {
  @extend %avatar;
  width: 72px;
  height: 72px;
}
.profile-pic-60 {
  @extend %avatar;
  width: 60px;
  height: 60px;
}

.profile-pic-56 {
  @extend %avatar;
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
}
.profile-pic-48 {
  width: 48px;
  height: 48px;
  @extend %avatar;
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(sm) {
  .profile-pic-72 {
    width: 50px;
    height: 50px;
  }
  .profile-pic-120 {
    width: 80px;
    height: 80px;
  }
  .profile-pic-140 {
    width: 65px;
    height: 65px;
  }
  .profile-pic-96 {
    width: 50px;
    height: 50px;
  }
  .profile-pic-56 {
    width: 40px;
    height: 40px;
  }
}
@include media-breakpoint-down(xs) {
}
