@import "./shared.scss";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import "./bootstrap";
@import "button";
@import "form";
@import "cards";
@import "sidebar";
@import "navigation";
@import "footer";
@import "profile-pic";
@import "typography";
@import "./tab-bar.scss";
@import "datepicker";
@import "backend";

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: $default;
  font-family: $font-family-base;
  font-weight: normal;
}

a,
a:hover,
a:focus,
.a,
.a:hover,
.a:focus {
  color: $default;
  text-decoration: none;
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.padder {
  padding: 8rem 0rem;
  @include media-breakpoint-down(sm) {
    padding: 6rem 0rem;
  }
}
.padder-main {
  padding: 5rem 0rem;
  @include media-breakpoint-down(lg) {
    padding: 7rem 0rem;
  }
  @include media-breakpoint-down(md) {
    padding: 3rem 0rem;
  }
}
.padder-top {
  padding-top: 6rem;
  @include media-breakpoint-down(lg) {
    padding-top: 3rem;
  }
}
.mb-5-half {
  margin-bottom: 2.5rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1.5rem;
  }
}

figure {
  margin-bottom: 0px;

  figcaption {
    margin-bottom: 0px;
  }
}
.fixed-bottom {
  z-index: 1025;
}
.mb-30 {
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
}
.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 500;
}
.section-border-top {
  border-top: 1px solid rgba($pale-blue, 0.5);
}
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.fs-12 {
  font-size: 12px;
}
.bb-1 {
  box-shadow: inset 0 -1px 0 0 $pale-blue;
}
.bt-1 {
  border-top: 1px solid $pale-blue;
}
.mt-72 {
  margin-top: 72px;
}

.bold {
  font-weight: bold;
}

.section-border-top {
  border-top: 1px solid rgba($pale-blue, 0.5);
}
.profile-pic-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.profile-pic-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.profile-pic-48 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.load-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4px;
  z-index: 100000;
  background-color: lighten($secondary, 20%);
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
    &:nth-child(1) {
      animation: loading 2.1s linear 0.7s infinite;
      background-color: $secondary;
    }
    &:nth-child(2) {
      animation: loading 2.1s linear 1.4s infinite;
      background-color: lighten($secondary, 20%);
    }
  }
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
.ui-blocker {
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 5000;
  position: fixed;
  top: 72px;
  left: 0px;
}

.view-all {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $secondary !important  ;
  }
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.view-all-dashboard {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $secondary;
  }
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  width: auto !important;
  &:last-child {
    margin-right: 0px !important;
  }
}

.live-tournament,
.quick-play,
.premium-games,
.upcoming-tournament,
.popular-host,
.free-to-play-games,
.news,
.prize-pull,
.about-mtn-treasure,
.enter-raffles-wrapper,
.active-raffle {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
}

.blank-space {
  height: 72px;
}
.mainContainer {
  margin-top: 72px;
}
.disable-scroll {
  overflow: hidden;
}
.btn-link {
  background: transparent;
}
.dynamic-banner {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
.selected-row {
  background-color: grey;
}

.disabledAd {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/////Table Sorting/////
th.sortable {
  position: relative;
  cursor: pointer;
}
th.sortable::after {
  content: "\f0dc";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 8px;
  color: white;
}
th.sortable.asc::after {
  content: "\f0d8";
}
th.sortable.desc::after {
  content: "\f0d7";
}
/////Table Sorting/////

.previewShown {
  display: block !important;
}

.previewHidden {
  display: none !important;
}

.su-iframe-center {
  margin: 0 auto;
  display: block;
}

.defualt-create-card {
  margin-left: 350px;
}

.platform-arcadex {
  background-color: #1a46e5;
  color: #fff;
}
.platform-nickx {
  background-color: #fc7323;
  color: #fff;
}
.nav-danger {
  background-color: red !important;
}
.card-header-pink {
  background-color: #ff355d !important;
}
