
.input-group-wrapper {
    position: relative;
}

.without-lable {
    position: relative;
    @include inputwithoutlabel();

    .form-control {
        font-size: 15px;
        font-weight: 500;
        background: transparent;
        

        &.textareaFiled {
            min-height: 35px;
            max-height: 150px;
            resize: none;
        }

        &:focus {
            box-shadow: none;
        }

        &::-moz-placeholder {
            color: $light-violet;

        }

        &::-webkit-input-placeholder {
            color: $light-violet;

        }

        &:-moz-placeholder {
            color: $light-violet;

        }

        &:-ms-input-placeholder {
            color: $light-violet;

        }

        &::-ms-input-placeholder {
            color: $light-violet;
        }
        
    }
    .field-icon {
        
        float: right;
        margin-left: -25px;
        margin-top: -25px;
        position: relative;
        z-index: 99999999999;
        
        width: 25px;
        height: 25px;

        &.eye-open {
            background-image: url('../assets/images/svg/eye-default.svg');
        }
        &.eye-close {
            background-image: url('../assets/images/svg/eye-close.svg');
        }
        
      }

    small {
        position: absolute;
        top: 50px;
        font-size: 12px;
        color: $bluish-grey;
        font-weight: normal;
        left: 0;
    }
    .input-group-wrapper {
        position: relative;
        .checked-playername {
            position: absolute;
            top: 0;
            right: 0;
        }
        &.search {
            img {
                position: absolute;
                top: 15px;
                left: 20px;

            }

            input {
                padding-left: 60px;
               height: 50px;
            }
        }
    }
    
}

.with-lable {
    position: relative;
    z-index: 1;
    @include inputwithlabel();

    .form-control {
        font-size: 15px;
        background: transparent;
        font-weight: 500;
        color: #3c4055;
        height: 40px;;

        &:focus {
            box-shadow: none;
        }

        &::-moz-placeholder {
            color: $light-violet
        }

        &::-webkit-input-placeholder {
            color: $light-violet
        }

        &:-moz-placeholder {
            color: $light-violet
        }

        &:-ms-input-placeholder {
            color: $light-violet
        }

        &::-ms-input-placeholder {
            color: $light-violet
        }
    }

    small {
        font-size: 12px;
        color: $bluish-grey;
        font-weight: normal;
        position: absolute;
        top: 50px;
        left:0;
    }
    .input-group-wrapper {
        position: relative;
        .checked-playername {
            position: absolute;
            top: 0;
            right: 0;
        }
        &.search {
            img {
                position: absolute;
                top: 15px;
                left: 20px;

            }

            input {
                padding-left: 60px;
               height: 50px;
            }
        }
    }
    .field-icon {
        
        float: right;
        margin-left: -25px;
        margin-top: -25px;
        position: relative;
        z-index: 99999999999;
        
        width: 25px;
        height: 25px;

        &.eye-open {
            background-image: url('../assets/images/svg/eye-default.svg');
        }
        &.eye-close {
            background-image: url('../assets/images/svg/eye-close.svg');
        }
      }

}

.with-lable-without-z-index {
    position: relative;    
    @include inputwithlabel();

    .form-control {
        font-size: 15px;
        background: transparent;
        font-weight: 500;
        color: #3c4055;
        height: 40px;;

        &:focus {
            box-shadow: none;
        }

        &::-moz-placeholder {
            color: $light-violet
        }

        &::-webkit-input-placeholder {
            color: $light-violet
        }

        &:-moz-placeholder {
            color: $light-violet
        }

        &:-ms-input-placeholder {
            color: $light-violet
        }

        &::-ms-input-placeholder {
            color: $light-violet
        }
    }

    small {
        font-size: 12px;
        color: $bluish-grey;
        font-weight: normal;
        position: absolute;
        top: 50px;
        left:0;
    }
    .input-group-wrapper {
        position: relative;
        .checked-playername {
            position: absolute;
            top: 0;
            right: 0;
        }
        &.search {
            img {
                position: absolute;
                top: 15px;
                left: 20px;

            }

            input {
                padding-left: 60px;
               height: 50px;
            }
        }
    }
    .field-icon {
        
        float: right;
        margin-left: -25px;
        margin-top: -25px;
        position: relative;
        z-index: 99999999999;
        
        width: 25px;
        height: 25px;

        &.eye-open {
            background-image: url('../assets/images/svg/eye-default.svg');
        }
        &.eye-close {
            background-image: url('../assets/images/svg/eye-close.svg');
        }
      }

}