/*---
Custom Color add to theme
---*/
.mb-6 {
  margin-bottom: 4rem; }

.banner-wrapper.aboutus-wrapper {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
  position: relative; }
  .banner-wrapper.aboutus-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/images/about-background.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1; }

span.btn-outline-secondary:hover {
  color: #fff !important; }

span.btn-secondary {
  color: #fff !important; }

.banner.aboutus {
  background-image: url("../../assets/images/aboutus-banner.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: contain;
  background-position: top right;
  z-index: 2; }
  .banner.aboutus .about-content {
    margin-top: 15rem; }
  .banner.aboutus .about-quote {
    margin-top: 10rem; }
  @media (max-width: 767.98px) {
    .banner.aboutus {
      background-image: none; }
      .banner.aboutus .about-content {
        margin-top: 2rem; }
      .banner.aboutus .about-quote {
        margin-top: 4rem; } }

.about-collumn-section {
  margin-top: 10rem;
  z-index: 2;
  padding-bottom: 5rem; }
  .about-collumn-section .about-sec-img {
    max-width: 40%; }
  .about-collumn-section [class^="col-"] {
    padding-left: 25px;
    padding-right: 25px; }
  @media (max-width: 767.98px) {
    .about-collumn-section {
      margin-top: 4rem; } }

.aboutus-offer-section {
  background: radial-gradient(circle at 115% 100%, #3e68ff, #1a46e5 40%, #0024a8);
  position: relative;
  overflow: hidden; }
  .aboutus-offer-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../../assets/images/sec-3bg.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.5; }
  .aboutus-offer-section .backstyle {
    position: relative;
    z-index: 2; }
    .aboutus-offer-section .backstyle .collumn-wrappper img {
      max-width: 50px; }
  .aboutus-offer-section .team-card {
    margin-top: 4rem; }

.featured-logo.about .swiper-slide img {
  max-width: 120px; }

.tournamet-info-wrapper {
  padding: 25px 0px;
  background: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24); }
  .tournamet-info-wrapper p {
    margin-bottom: 0px; }
  .tournamet-info-wrapper .responsive-adjust .media .timer {
    max-width: 32px;
    max-height: 32px; }
  .tournamet-info-wrapper .responsive-adjust .media .media-body span {
    font-weight: 500; }
  .tournamet-info-wrapper .responsive-adjust .ms-flex {
    flex: 1;
    -ms-flex: 1; }
  .tournamet-info-wrapper .counter-btn-wraper span {
    font-weight: normal;
    font-size: 12px; }
  .tournamet-info-wrapper .mt-negative-50 {
    margin-top: -50px; }
  .tournamet-info-wrapper .profile-avatar {
    max-width: 140px;
    width: 100%;
    box-shadow: 0 9px 20px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
    @media (max-width: 991.98px) {
      .tournamet-info-wrapper .profile-avatar {
        max-width: 65px; } }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper .media {
      margin-top: 5px; }
    .tournamet-info-wrapper .countDown-timer {
      font-size: 11px; } }
  @media (max-width: 767.98px) {
    .tournamet-info-wrapper {
      padding: 12px 0px; }
      .tournamet-info-wrapper .title-small-responsive {
        font-size: 18px; }
      .tournamet-info-wrapper .countDown-timer {
        font-size: 11px; }
      .tournamet-info-wrapper .responsive-adjust [class^="col"] {
        padding-left: 0px; }
      .tournamet-info-wrapper .responsive-adjust a.btn.ml-2 {
        margin-left: 0px !important; }
      .tournamet-info-wrapper .responsive-adjust h6 {
        font-size: 16px; }
      .tournamet-info-wrapper .responsive-adjust .media {
        margin-top: 10px; }
        .tournamet-info-wrapper .responsive-adjust .media .timer {
          max-width: 24px;
          max-height: 24px; }
        .tournamet-info-wrapper .responsive-adjust .media .media-body h6 {
          font-size: 12px; } }
  .tournamet-info-wrapper.host-detail .social-media img {
    width: 7vw;
    max-width: 28px;
    height: 7vw;
    max-height: 28px; }
  @media (max-width: 991.98px) {
    .tournamet-info-wrapper.host-detail {
      padding: 20px 0px; }
      .tournamet-info-wrapper.host-detail .host-heading {
        margin-top: -15px;
        margin-bottom: 20px; }
        .tournamet-info-wrapper.host-detail .host-heading h1 {
          font-size: 21px; } }

.banner-advertising img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top; }

@media (max-width: 991.98px) {
  .banner-advertising {
    width: 100%;
    height: 150px; } }

@media (max-width: 767.98px) {
  .banner-advertising {
    width: 100%;
    height: 100%; } }

.banner-advertising.lg {
  width: 970px;
  height: 250px;
  margin-top: 70px;
  margin-bottom: 70px; }

.banner-advertising.sm {
  width: 728px;
  height: 90px; }

.banner-advertising.web-square {
  width: 300px;
  height: 250px; }

.banner-advertising.mt-6 {
  margin-top: 5rem; }
