/*---
Custom Color add to theme
---*/
.form-info ul li.list-group-control {
  padding-left: 5px;
  padding-right: 5px; }
  @media (max-width: 767.98px) {
    .form-info ul li.list-group-control {
      padding-left: 0px;
      padding-right: 0px; } }

.form-info ul li .select-game-box {
  background: #fff;
  padding: 15px 10px;
  box-shadow: 0 10px 10px 5px rgba(167, 184, 245, 0.05);
  border: 1px solid #aabaca;
  height: 60px; }
  .form-info ul li .select-game-box label.custom-control {
    cursor: pointer;
    padding-left: 0px; }
    .form-info ul li .select-game-box label.custom-control.custom-radio {
      --color: #1a46e5; }
      .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-input {
        display: none; }
        .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
          background-color: var(--color);
          border-color: var(--color);
          background-size: 100%; }
      .form-info ul li .select-game-box label.custom-control.custom-radio .custom-control-indicator {
        background-image: url("../../assets/images/checked-circle.png");
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 4px;
        right: 0;
        width: 20px;
        height: 20px;
        border: 2px solid #a3b8cc;
        transition: 0.3s;
        background: transperent;
        background-size: 0%;
        padding: 3px;
        background-position: center;
        background-repeat: no-repeat; }
  .form-info ul li .select-game-box label .labelc-content img {
    width: 30px; }

.custom-control.fill-checkbox {
  --color: #1a46e5; }
  .custom-control.fill-checkbox .fill-control-input {
    display: none; }
    .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
      background-color: var(--color);
      border-color: var(--color);
      background-size: 80%; }
  .custom-control.fill-checkbox .fill-control-indicator {
    background-image: url("../../assets/images/checked.png");
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #a3b8cc;
    transition: 0.3s;
    background: transperent;
    background-size: 0%;
    padding: 3px;
    background-position: center;
    background-repeat: no-repeat; }
    .custom-control.fill-checkbox .fill-control-indicator.backend {
      left: auto;
      right: 0; }
  .custom-control.fill-checkbox .fill-control-description {
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 28px;
    padding-left: 10px; }
    .custom-control.fill-checkbox .fill-control-description a {
      color: #1a46e5; }

.zIndex-1 {
  z-index: 100; }

.create-raffle-btn {
  min-width: 200px; }
